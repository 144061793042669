import React, { useState } from 'react';
import { Game } from '../../types/game';
import { Link } from 'react-router-dom';
import { getGameImageUrl } from '../../services/api';

interface GameCardProps {
  game: Game;
  onClick?: () => void;
}

export const GameCard: React.FC<GameCardProps> = ({ game, onClick }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div 
      onClick={onClick}
      className="bg-white/10 rounded-lg overflow-hidden hover:bg-white/15 transition-colors cursor-pointer group"
    >
      <div className="aspect-square relative bg-white/10">
        <img 
          src={getGameImageUrl(game.kv_key)}
          alt={game.name}
          className="w-full h-full object-cover transition-transform group-hover:scale-105"
          onError={(e) => {
            // If image fails to load, show a colored div with the first letter
            setImageError(true);
            e.currentTarget.style.display = 'none';
          }}
        />
        {imageError && (
          <div className="absolute inset-0 flex items-center justify-center bg-emerald-600 text-white text-4xl font-bold">
            {game.name.charAt(0).toUpperCase()}
          </div>
        )}
      </div>
      <div className="p-4">
        <h3 className="font-medium text-lg mb-1 truncate">{game.name}</h3>
        <Link 
          to={`/user/${game.author.username}`}
          onClick={(e) => e.stopPropagation()}
          className="block text-sm text-emerald-400 hover:text-emerald-300 transition-colors mb-2 truncate"
        >
          @{game.author.username}
        </Link>
        <div className="flex items-center space-x-4 text-sm text-white/40">
          <span className="flex items-center">
            <svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            {game.likes_count}
          </span>
          <span className="flex items-center">
            <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
            {game.views_count}
          </span>
        </div>
      </div>
    </div>
  );
}; 